<template>
    <transition name="fade" v-if="isOpen">
        <div class="
            fixed top-0 right-0 left-0 bottom-0 z-50 px-4 min-h-screen md:flex md:items-center md:justify-center
            py-4
        ">
            <div class="bg-black opacity-25 w-full h-full absolute z-10 inset-0"></div>
            <div class="bg-white md:rounded-lg md:mx-auto p-4 fixed inset-x-0 bottom-0 z-50 md:mb-4 md:relative overflow-y-auto max-h-full" 
            :class="
                size == 'medium' ? 'md:max-w-md' : 
                (size == 'large' ? 'md:w-2/4' : '')
            ">
                <slot></slot>
            </div>
        </div>
    </transition >
</template>
<script >
    export default {
        name : 'Modal',
        props: {
            isOpen : {
                type   : Boolean,
                default: false
            },
            size : {
                type   : String,
                default: 'medium'
            }
        }
  };

</script>