<template>
    <div class="px-3 md:px-0 pt-4">
        <h1 class="text-xl leading-8 font-semibold tracking-tight text-gray-900 capitalize mb-2">
            Laporkan Soal
        </h1>
        <p class="text-gray-700">Silahkan pilih salah satu masalah yang anda temui dalam soal. Kemudian jelaskan permasalahan tersebut dengan singkat!</p>

        <form class="mt-8 mb-10 space-y-7 bg-white p-3 pt-4 rounded-lg" @submit="submitLaporan()">
            <custom-input id="nama_lengkap" type="text" label="Nama Lengkap" v-model="mainForm.nama_lengkap" :errorMessage="errorForm.nama_lengkap"/>

            <div class="option">
                <div :key="index" v-for="(data, index) in opsi" class="option-item 
                    bg-white rounded-lg 
                    px-3 py-2 mb-2 cursor-pointer 
                    border-2
                    transition-all hover:bg-gray-50 hover:border-yellow-200 flex items-center"
                    :class="(mainForm.status_report == data.id) ? 'border-yellow-600' : 'border-gray-200' "
                    @click="pilihOpsi(data.id)"
                >
                    <div v-html="data.label" class="flex-grow"></div>
                    <div class="ml-1">
                        <span class="w-4 h-4 inline-block mr-1 rounded-full border "
                            :class="(mainForm.status_report == data.id) ? 'bg-yellow-600 border-yellow-600' : 'border-gray-200' "
                        ></span>
                    </div>
                </div> 
            </div>
            <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in" v-if="(errorForm.status_report != '' && errorForm.status_report != null)">
                <div class="text-xs text-red-700 mt-0.5">Mohon memilih salah satu jenis kendala</div>
            </transition>

            <custom-text-area id="keterangan" type="text" label="Keterangan" v-model="mainForm.keterangan" :errorMessage="errorForm.keterangan"/>

            <button type="submit"
                @click="submitLaporan()" 
                :disabled="isLoading"
                class="group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-lg rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                    Kirim
            </button>

        </form>

        <loading-modal :isShow="isLoading" />

        <modal :isOpen="isShowModalSuccess" >
            <div class="text-center">
                <div class="w-36 mx-auto">
                    <div class="h-36 w-36 rounded-lg content-center flex items-center text-center bg-yellow-200">
                        <img src="@/assets/images/thank-you.svg" class="w-24 h-24 mx-auto" />
                    </div>
                </div>

                <h2 class="mt-2 font-semibold text-lg">Laporanmu Diterima</h2>
                <p>Terimakasih telah membantu melaporkan soal</p>
            </div>

            <div class="flex space-x-1 mt-2">
                <button @click="backButton()" class="px-3 py-2 bg-gray-100 text-gray-800 rounded-lg mt-1 text-center cursor-pointer hover:bg-gray-200 flex-grow">Kembali</button>
            </div>

        </modal>

    </div>
</template>
<script>
import CustomInput from '../components/CustomInput.vue';
import CustomTextArea from '../components/CustomTextArea.vue';
import LoadingModal from '../components/LoadingModal.vue';
import Modal from '../components/Modal.vue';
import { errorHandler, extractValidationError, showErrorNotif, showSuccessNotif } from '../helpers/Tools';
export default {
    name : 'ReportSoal',
    components : {
        CustomInput,
        CustomTextArea,
        LoadingModal,
        Modal
    },
    computed: {
        userLogin () {
            return this.$store.getters.userLogin
        },
    },
    data () {
        return {
            isLoading : false,
            isShowModalSuccess : false,
            opsi: [
                {id : 1, label : 'Soal salah ketik/tidak lengkap'},
                {id : 2, label : 'Pilihan jawaban tidak relevan dengan soal'},
                {id : 3, label : 'Gambar terpotong/tidak jelas'},
                {id : 4, label : 'Tidak ada jawaban benar'},
                {id : 5, label : 'Lainnya'},
            ],

            mainForm: {
                status_report: null,
                nama_lengkap : null,
                keterangan   : null,
                id_kuis_soal : this.$route.params.id_kuis_soal
            },

            errorForm: {
                status_report: null,
                nama_lengkap : null,
                keterangan   : null,
                id_kuis_soal : null
            },
        }
    },
    mounted() {
        this.initData();
    },
    methods : {
        initData () {
            this.mainForm.nama_lengkap = this.userLogin.user.nama_lengkap;
        },
        pilihOpsi(id) {
            this.mainForm.status_report = id;
        },

        backButton() {
            let urutan = this.$route.query.urutan
            this.$router.replace({ 
                name: 'QuizReviewDetail', 
                params: { 
                    singkatan_kategori: this.$route.params.singkatan_kategori,
                    id_kuis           : this.$route.params.id_kuis
                },
                query : {
                    urutan : urutan
                }
            });
        },

        async submitLaporan() {
            this.isLoading = true;

                try {
                    this.errorForm = {
                        status_report: null,
                        nama_lengkap : null,
                        keterangan   : null,
                        id_soal      : null,
					};

					let res            = await this.$store.dispatch("postReportSoal", this.mainForm);
					let result         = res.data;
					    this.isLoading = false;

					if(result.status == 'success') {
                        this.isShowModalSuccess = true;
					} else {
                        this.errorForm = extractValidationError(this.errorForm, result.data);
					}

                } catch (error) {
                    this.isLoading = false;
                    errorHandler(this, error);
                }
        }
       
    }
}
</script>