<template>
    <div class="custom-input">
        <div class="input-component" :class="(value == '' || value == null) ? 'empty' : '' ">
            <div class="relative">
            <input
                autocomplete="off"
                :id="id"
                :type="type" 
                :value="value"
                @input="updateValue($event.target.value)"
                class="h-full w-full border-gray-300 px-4 py-3 transition-all border-blue rounded-lg bg-white outline-none"
            />
            <label :for="id" class="absolute left-2 transition-all bg-gray-50 px-1">
                {{ label }}
            </label>
            </div>
            <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in" v-if="(errorMessage != '' || errorMessage != null)">
                <div class="text-xs text-red-700 mt-0.5">{{ errorMessage }}</div>
            </transition>
        </div>
    </div>
</template>
<script>
export default {
    name: 'CustomInput',
    props: {
        value : {
            type : String,
        },
        errorMessage : {
            type : String,
            default : ''
        },
        label : {
            type   : String,
            required: true
        },
        type : {
            type   : String,
            default: 'text'
        },
        id : {
            type: String,
            required: true
        }
    },
    methods: {
        updateValue (value) {
            this.$emit('input', value)
        }
    }
}
</script>
